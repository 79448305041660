import { CircularProgress, Typography } from '@mui/material'
import React from 'react'
import styles from './styles.module.scss'

function AppLoadingIndicator() {
    return (
        <div className={styles.container}>
            <CircularProgress></CircularProgress>
            <Typography>Retrieving Estimate</Typography>
        </div>
    )
}

export default AppLoadingIndicator