import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { CustomerEstimate } from 'api/types/CustomerEstimate'
import { RootState } from 'rootReducer'
import { AppThunk } from 'store'
import updateEstimateMessage from 'api/updateEstimateMessage'
import getCustomerEstimate from 'api/getCustomerEstimate'
import createEstimateLineItem from 'api/createEstimateLineItem'
import updateEstimateLineItem from 'api/updateEstimateLineItem'
import deleteEstimateLineItem from 'api/deleteEstimateLineItem'
import { MpviLineItem } from 'types/MpviLineItem'
import { LineItem } from 'api/types/LineItem'
import { MpviInspection } from 'types/MpviInspection'

export enum errorTypes {
    NONE,
    LOAD_FAILURE,
    ADD_FAILURE,
    UPDATE_FAILURE,
    REMOVE_FAILURE,
    UPDATE_ESTIMATE_FAILURE
}

export const lineItemsAdapter = createEntityAdapter<MpviLineItem>({
    selectId: (lineItem) => lineItem.ID
})

export const inspectionsAdapter = createEntityAdapter<MpviInspection>({
    selectId: (inspection) => inspection.ID
})

interface IInspectionPayload {
    inspections: MpviInspection[],
    lineItems: MpviLineItem[],
}

interface IState {
    isLoading: boolean,
    error: errorTypes,
    lineItems: EntityState<MpviLineItem>,
    inspections: EntityState<MpviInspection>,
}

const initialState: IState = {
    isLoading: false,
    error: errorTypes.NONE,
    lineItems: lineItemsAdapter.getInitialState(),
    inspections: inspectionsAdapter.getInitialState(),
}

const slice = createSlice({
    name: `advisorInspectionView`,
    initialState: initialState,
    reducers: {
        getInspectionDataStart(state) {
            state.error = errorTypes.NONE
            state.isLoading = true
        },
        getInspectionDataFinish(state, action: PayloadAction<IInspectionPayload>) {
            state.isLoading = false
            inspectionsAdapter.setAll(state.inspections, action.payload.inspections)
            lineItemsAdapter.setAll(state.lineItems, action.payload.lineItems)
        },
        getInspectionDataFailure(state) {
            state.isLoading = false
            state.error = errorTypes.LOAD_FAILURE
        },
        lineItemAdded(state, action: PayloadAction<MpviLineItem>) {
            lineItemsAdapter.addOne(state.lineItems, action.payload)
        },
        addLineItemFailure(state) {
            state.error = errorTypes.ADD_FAILURE
        },
        lineItemUpdated(state, action: PayloadAction<Update<MpviLineItem>>) {
            lineItemsAdapter.updateOne(state.lineItems, action.payload)
        },
        updateLineItemFailure(state) {
            state.error = errorTypes.UPDATE_FAILURE
        },
        lineItemRemoved(state, action: PayloadAction<number>) {
            lineItemsAdapter.removeOne(state.lineItems, action.payload)
        },
        removeLineItemFailure(state) {
            state.error = errorTypes.REMOVE_FAILURE
        },
        updateEstimateMessageFailure(state) {
            state.error = errorTypes.UPDATE_ESTIMATE_FAILURE
        },
        errorCleared(state) {
            state.error = errorTypes.NONE
        }
    },
})

export const {
    getInspectionDataStart,
    getInspectionDataFinish,
    getInspectionDataFailure,
    lineItemAdded,
    addLineItemFailure,
    lineItemUpdated,
    updateLineItemFailure,
    lineItemRemoved,
    removeLineItemFailure,
    updateEstimateMessageFailure,
    errorCleared,
} = slice.actions

export const lineItemsSelectors = lineItemsAdapter.getSelectors<RootState>(state => state.advisorInspectionView.lineItems)
export const inspectionSelectors = inspectionsAdapter.getSelectors<RootState>(state => state.advisorInspectionView.inspections)

export default slice.reducer

// Thunks
export const getInspectionData = (): AppThunk => async (dispatch, getState) => {

    try {

        const estimateKey = getState().app.estimateKey
        dispatch(getInspectionDataStart())

        const res: CustomerEstimate = await getCustomerEstimate(estimateKey)
        
        let lineItems: MpviLineItem[] = res.LineItems.map(li => {
            let inspection = res.Inspections.find(i => i.ID === li.InspectionID)

            return {
                ...li,
                Inspection: inspection ? inspection : null
            }
        })

        dispatch(getInspectionDataFinish({
            inspections: res.Inspections,
            lineItems: lineItems,
        }))
        
    } catch (error) {

        dispatch(getInspectionDataFailure())

    }   

}

export const createLineItem = (lineItem: Partial<MpviLineItem>): AppThunk => async (dispatch, getState) => {

    try {

        const estimateKey = getState().app.estimateKey
        const res: LineItem = await createEstimateLineItem(estimateKey, lineItem)

        dispatch(lineItemAdded({
            ...res,
            Inspection: res.Inspection
        }))

    } catch (error) {

        dispatch(addLineItemFailure())

    }

}

export const updateLineItem = (lineItem: MpviLineItem): AppThunk => async (dispatch, getState) => {

    try {

        const estimateKey = getState().app.estimateKey
        await updateEstimateLineItem(estimateKey, lineItem)

        dispatch(lineItemUpdated({
            id: lineItem.ID,
            changes: lineItem,
        }))

    } catch (error) {

        dispatch(updateLineItemFailure())

    }

}

export const deleteLineItem = (id: MpviLineItem['ID']): AppThunk => async (dispatch, getState) => {

    try {

        const estimateKey = getState().app.estimateKey
        await deleteEstimateLineItem(estimateKey, id)

        dispatch(lineItemRemoved(id))

    } catch (error) {

        dispatch(removeLineItemFailure())

    }

}

export const updateEstimate = (message: string): AppThunk => async (dispatch, getState) => {

    try {

        const estimateKey = getState().app.estimateKey
        await updateEstimateMessage(estimateKey, message)

    } catch(error) {

        dispatch(updateEstimateMessageFailure())

    }

}