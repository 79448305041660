import { TableRow, TableCell } from "@mui/material"
import React from "react"
import { MpviInspection } from "types/MpviInspection"
import AcceptedIcon from '@mui/icons-material/CheckCircleOutline';

interface IInspectionRows{
    inspections: MpviInspection[]
}

function InspectionRows({
    inspections
}: IInspectionRows){

    const sortedInspections = inspections.sort((itemOne, itemTwo) => {
        const itemOneSectionName = itemOne.SectionName
        const itemTwoSectionName = itemTwo.SectionName

        return itemOneSectionName < itemTwoSectionName ? -1 :
            (itemOneSectionName > itemTwoSectionName) ? 1 : 0
    })

    return (
        <>
            {sortedInspections.map(item => {
                return (
                    <TableRow key={`line-item-${item.ID}`}>
                        <TableCell>{item.SectionName}</TableCell>
                        <TableCell>{item.Name}</TableCell>
                        <TableCell align={`center`}>
                            <AcceptedIcon htmlColor={`#43a047`}/>
                        </TableCell>
                    </TableRow>
                )
            })}
        </>
    )
}

export default InspectionRows