import { Box, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'
import React from 'react'
import { MpviInspection } from 'types/MpviInspection'

export type InspectionCategory = {
    id: number,
    name: string,
    inspections: MpviInspection[],
}

export interface IListView {
    categories: InspectionCategory[]
}

function ListView({
    categories
}: IListView) {
    const content = categories.map(category => (
        <Table size={`small`} key={category.id}>
            <TableHead>
                <TableRow>
                    <TableCell colSpan={2}>{category.name}</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {category.inspections.map((item, index) => (
                    <TableRow key={item.ID}>
                        {index === 0 &&
                            <TableCell 
                                padding={`none`}
                                rowSpan={category.inspections.length}
                                width={25}
                                style={{borderBottom: `none`}}
                            ></TableCell>
                        }
                        <TableCell>{item.Name}</TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    ))

    return (
        <Box width={`100%`}>{content}</Box>
    )
}

export default ListView