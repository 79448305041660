import { Box, Card, Divider, Stack, Typography } from "@mui/material"
import { LineItemSource } from "api/types/LineItem"
import IconField from "components/IconField"
import React from "react"
import { MpviInspection } from "types/MpviInspection"
import { MpviLineItem } from "types/MpviLineItem"
import LineItemTable from "../LineItemTable"
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import SourceChip from "./components/SourceChip"

interface IInspectionDetails {
    name: string,
    sectionName: string,
    source: LineItemSource,
    inspection?: MpviInspection,
    lineItems: MpviLineItem[]
}

function InspectionDetails({
    name,
    sectionName,
    source,
    inspection = undefined,
    lineItems
}: IInspectionDetails) {
    return (
        <Card>
            <Box sx={{p: 2, display: `flex`}}>
                <IconField
                    itemIconProps={{
                        source: source,
                        severity: inspection?.Severity
                    }}
                ></IconField>
                <Stack sx={{flexGrow: 1, alignItems: "flex-start", marginLeft: `16px`, marginRight: `16px`}} spacing={0.5}>
                    <Typography fontWeight={700}>{name}</Typography>
                    <Typography variant="body2" color="text.secondary">{sectionName}</Typography>
                </Stack>
                <Stack
                    direction={`row`}>
                    {(source !== LineItemSource.EADVISOR_ADDITIONAL && lineItems.length === 0) && 
                          <PriorityHighIcon color={`error`} />}
                          
                    <SourceChip source={source}/>
                </Stack>
            </Box>
            <Divider />
            <LineItemTable
                lineItems={lineItems}
                source={source}
                inspectionID={inspection === undefined ? null : inspection?.ID}/>
        </Card>
    )
}

export default InspectionDetails