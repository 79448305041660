import Customer from '../Customer'
import Dealer from '../Dealer'
import { BaseInspection } from '../Inspection'
import { BaseLineItem } from '../LineItem'
import { User } from '../User'
import Vehicle from '../Vehicle'
import Video from '../Video'

export enum EstimateStatus {
    PENDING_ESTIMATE,
    PENDING_APPROVAL,
    CUSTOMER_DECLINED,
    CUSTOMER_APPROVED
}

export enum MPVIStatus {
    NOT_STARTED,
    IN_PROGRESS,
    WAITING_ON_ESTIMATE,
    WAITING_ON_APPROVAL,
    MPVI_SENT,
    MPVI_OPENED,
    WAITING_ON_PARTS,
    SERVICE_DECLINED,
    VEHICLE_IN_SERVICE,
    COMPLETE
}

export type BaseCustomerEstimate = {
    ID: number
    CustomerID: number
    RepairOrderID: number
    ApprovalCompletedOnWorkbench: boolean
    EstimateKey: string
    Message: string
    RONumber: string
    Signature: string
    Status: EstimateStatus
    VIN: string
    VisitID: number | null
    WorkbenchUrl: string
}

export type CustomerEstimate = BaseCustomerEstimate & {
    Customer: Customer
    CustomerVehicle: Vehicle
    Dealer: Dealer
    Inspections: BaseInspection[]
    LineItems: BaseLineItem[]
    MPVIStatus: MPVIStatus
    User: User
    Videos: Video[]
}