import { TableContainer, Paper, Table, TableHead, TableRow, TableCell, TableBody } from "@mui/material"
import { LineItemSource } from "api/types/LineItem"
import React from "react"
import { MpviLineItem } from "types/MpviLineItem"
import NewLineItemTableRow from "./components/NewLineItemTableRow";
import LineItemTableRow from "./components/LineItemTableRow";

interface ILineItemTable {
    lineItems: MpviLineItem[]
    source: LineItemSource,
    inspectionID: number | null,
}

function LineItemTable({
    lineItems,
    source,
    inspectionID = null
}: ILineItemTable) {

    return (
        <TableContainer component={Paper}>
            <Table sx={{ width: '100%' }} size="small" aria-label="a dense table">
                {(source !== LineItemSource.EADVISOR_ADDITIONAL && lineItems.length === 0) && 
                        <caption style={{color: 'red'}}>*There are currenly no line items for this inspection.</caption>}
                <TableHead>
                    <TableRow>
                        <TableCell>Name</TableCell>
                        <TableCell>Price</TableCell>
                        {source !== LineItemSource.DMS && <TableCell></TableCell>}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {lineItems.map((lineItem) => (
                        <LineItemTableRow
                            key={lineItem.ID}
                            lineItem={lineItem}/>
                    ))}
                    {source !== LineItemSource.DMS &&
                        <NewLineItemTableRow 
                            key={lineItems.length}
                            inspectionID={inspectionID}/>}
                </TableBody>
            </Table>
        </TableContainer>
    )
}

export default LineItemTable