import apiFetch from "api/apiFetch"

const updateCustomerEstimateReadStatus = (estimateKey: string): Promise<void> => {

    return apiFetch(`api/mpvi/estimate/${estimateKey}/opened`, {
        method: 'PUT'
    })

}

export default updateCustomerEstimateReadStatus