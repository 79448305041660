import { Box, Typography } from '@mui/material'
import React from 'react'
import { useTypedSelector } from 'store'

function ErrorView() {
    const errorText = useTypedSelector(state => state.app.errorText)

    return (
        <Box textAlign={`center`}>
            <Typography variant="h6">
                Something went wrong!
            </Typography>
            {errorText ?
                <Typography component="p">{errorText}</Typography> :
                <Typography component="p">
                    We are sorry about that, but that estimate is not available right now
                </Typography>
            }
        </Box>
    )
}

export default ErrorView