import apiFetch from "api/apiFetch"

const deleteEstimateLineItem = (estimateKey: string, id: number): Promise<void> => {

    return apiFetch(`api/mpvi/estimate/${estimateKey}/line-items/${id}`, {
        method: 'DELETE',
    })

}

export default deleteEstimateLineItem