import { Chip, ChipProps } from "@mui/material"
import { LineItemSource } from "api/types/LineItem"
import React from "react"

interface ISourceChip {
    source: LineItemSource
}

interface IChipProps {
    label: string,
    color: "default" | "primary" | "secondary" | "info" | "error" | "success" | "warning" | undefined
}

function SourceChip({
    source
}: ISourceChip){
    let chipProps: IChipProps = source === LineItemSource.DMS ?
                    {label: `DMS`, color: `default`} :
                    (source === LineItemSource.EADVISOR_ADDITIONAL ?
                        {label: `eAdvisor Additional`, color: `primary`} :
                        (source === LineItemSource.GM_MPVI ?
                            {label: `GM MPVI`, color: `info`} : 
                            {label: `eAdvisor MPVI`, color: `secondary`}))

    let props: ChipProps = {
        label: chipProps.label,
        color: chipProps.color,
        variant: `outlined`,
        size: `small`
    }

    return (
        <Chip {...props} />
    )
}

export default SourceChip