import React, { useMemo } from 'react'
import { setMode } from 'modules/app'
import { useAppDispatch, useTypedSelector } from 'store'
import modes from 'enums/appModes'
import AppGrid from 'componentsUI/AppGrid'
import ItemIcon from 'components/ItemIcon'
import { videosSelectors } from 'modules/videos'
import { useSelector } from 'react-redux'
import { lineItemSelectors, inspectionSelectors } from 'modules/customerInspectionView'
import {titleCase} from 'voca'
import { MpviLineItem } from 'types/MpviLineItem'
import { InspectionSeverity } from 'api/types/Inspection'
import { LineItemSource } from 'api/types/LineItem'
import { Avatar, Box, Button, Divider, ListItem, ListItemAvatar, ListItemText, Paper, Typography } from '@mui/material'
import MessageIcon from '@mui/icons-material/Message';
import getUnsuppressedLineItems from 'helpers/getUnsuppressedLineItems'

function Summary() {
    const dispatch = useAppDispatch()
    const inspections = useSelector(inspectionSelectors.selectAll)
    const lineItems = useSelector(lineItemSelectors.selectAll)
    const user = useTypedSelector(state => state.user)
    const vehicle = useTypedSelector(state => state.vehicle)
    const dealer = useTypedSelector(state => state.dealer)
    const videos = useTypedSelector(state => videosSelectors.selectAll(state)).filter(video => video.Transcoded);
    const videosCount = videos.length

    const message = useTypedSelector(state => state.estimateMessage.message)

    const lengthBySeverity = (lineItems: MpviLineItem[], severity: InspectionSeverity): number => lineItems.filter(item => item.Inspection?.Severity === severity).length;
    
    const unsuppressedLineItems = useMemo(() => getUnsuppressedLineItems(lineItems), [lineItems])

    const numSafetyConcerns: number = useMemo(() => lengthBySeverity(unsuppressedLineItems, InspectionSeverity.SAFETY_CONCERN), [unsuppressedLineItems])
    const numWarnings: number = useMemo(() => lengthBySeverity(unsuppressedLineItems, InspectionSeverity.WARNING), [unsuppressedLineItems])
    const numOkItems: number = useMemo(() => inspections.filter(i => i.Severity === InspectionSeverity.OK), [inspections]).length
    const numAdditional: number = useMemo(() => unsuppressedLineItems.filter(item => item.Source === LineItemSource.EADVISOR_ADDITIONAL && item.InspectionID === null).length, [unsuppressedLineItems])
    const numInitialWorkItems: number = useMemo(() => unsuppressedLineItems.filter(item => item.Source === LineItemSource.DMS).length, [unsuppressedLineItems])

    const numOfRecommendations: number = numSafetyConcerns + numWarnings + numAdditional

    const handleContinueClick = () => {
        dispatch(setMode(videosCount === 0 ? modes.INSPECTION : modes.VIDEO))
    }

    const getDivider = () => {

        return (

            <Box mt={2} mb={2}>
                <Divider/>
            </Box>

        )

    }

    const getMessageSection = () => {

        return message && message.trim() !== `` ? (
            <Box mb={2}>
                <ListItem>
                    <ListItemAvatar>
                    <Avatar>
                        <MessageIcon />
                    </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary={`A message from ${titleCase(user.FirstName)}:`} secondary={message} />
                </ListItem>
            </Box>
        ) : null

    }

    return (
        <Paper elevation={2}>
            <Box p={2}>
                <Box marginBottom={2}>
                    <Typography variant={`body2`}>{dealer?.Name}</Typography>
                    <Typography variant={`body2`}>{dealer?.Address1} {dealer?.Address2}</Typography>
                    <Typography variant={`body2`}>{dealer?.City}, {dealer?.Region} {dealer?.PostalCode}</Typography>
                </Box>
                {getDivider()}
                <Box mb={1}>
                    <Typography>
                        Your service advisor <Box fontWeight={`fontWeightMedium`} component={`span`}>{titleCase(user.FullName)}</Box> has {numOfRecommendations} recommendations for your <Box fontWeight={`fontWeightMedium`} component={`span`}>{titleCase(`${vehicle?.Year} ${vehicle?.AutomakerBrandName} ${vehicle?.AutomakerModelName}`)}</Box>:
                    </Typography>
                </Box>

                {getMessageSection()}

                <AppGrid
                    rowSpacing={1}
                    boxProps={{
                        pl:2,
                        pr:2,
                        mb:1,
                    }}
                >
                    <ItemIcon
                        itemCount={numInitialWorkItems}
                        source={LineItemSource.DMS}
                    ></ItemIcon>
                    <ItemIcon
                        itemCount={numSafetyConcerns}
                        source={LineItemSource.GM_MPVI}
                        severity={InspectionSeverity.SAFETY_CONCERN}
                    ></ItemIcon>
                    <ItemIcon
                        itemCount={numWarnings}
                        source={LineItemSource.GM_MPVI}
                        severity={InspectionSeverity.WARNING}
                    ></ItemIcon>
                    <ItemIcon
                        itemCount={numOkItems}
                        source={LineItemSource.GM_MPVI}
                        severity={InspectionSeverity.OK}
                    ></ItemIcon>
                    <ItemIcon
                        itemCount={numAdditional}
                        source={LineItemSource.EADVISOR_ADDITIONAL}
                    ></ItemIcon>
                </AppGrid>
                {getDivider()}
                <AppGrid
                    justifyContent={`end`}
                >
                    <Button
                        variant={`contained`}
                        color={`primary`}
                        onClick={handleContinueClick}
                    >Continue</Button>
                </AppGrid>
            </Box>
        </Paper>
    )
}

export default Summary