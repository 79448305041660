import { IconButton, TableCell, TableRow } from "@mui/material"
import { deleteLineItem, updateLineItem } from "modules/advisorInspectionView"
import React from "react"
import { useAppDispatch } from "store"
import { MpviLineItem } from "types/MpviLineItem"
import PricedLineItemCell from "../PricedLineItemCell"
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import TrashIcon from '@mui/icons-material/Delete';
import { LineItemSource } from "api/types/LineItem"
import Tooltip from "@mui/material/Tooltip"
import styles from './styles.module.scss'

interface ILineItemTableRow {
    lineItem: MpviLineItem
}

function LineItemTableRow({
    lineItem
} : ILineItemTableRow){
    const dispatch = useAppDispatch()

    const isDeleted = lineItem.IsDeleted
    
    const handleDeleteItem = () => {
        if(lineItem.Source === LineItemSource.EADVISOR_ADDITIONAL)
            dispatch(deleteLineItem(lineItem.ID))
        else
            dispatch(updateLineItem({...lineItem, IsDeleted: !lineItem.IsDeleted}))
    }

    const DeleteIcon = () => {
        const isAdditional = lineItem.Source === LineItemSource.EADVISOR_ADDITIONAL
        const tooltip = isAdditional ? `Delete` : isDeleted ?  `Unsupress` : `Suppress`

        return (
            <Tooltip title={`${tooltip} line item`}>
                {isAdditional ?
                    <TrashIcon/> :
                    isDeleted ? 
                        <VisibilityIcon/> : 
                        <VisibilityOffIcon/>}
            </Tooltip>
        )
    }

    return (
        <TableRow
            key={lineItem.ID}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            className={isDeleted ? styles.suppressed : ``}>

            <TableCell component="th" scope="row">
                {lineItem.Name}
            </TableCell>
            <TableCell width={'150px'}>
                <PricedLineItemCell
                    lineItem={lineItem}/>
            </TableCell>
            {lineItem.Source !== LineItemSource.DMS && 
                <TableCell align="right">
                    <IconButton
                        onClick={handleDeleteItem}>
                        <DeleteIcon/>
                    </IconButton>
                </TableCell>}
        </TableRow>
    )
}

export default LineItemTableRow