import { TableRow, TableCell } from "@mui/material";
import { LineItemStatus } from "api/types/LineItem";
import formatPrice from "helpers/formatPrice";
import React from "react";
import { MpviLineItem } from "types/MpviLineItem";
import AcceptedIcon from '@mui/icons-material/CheckCircleOutline';
import DeclinedIcon from '@mui/icons-material/HighlightOff';

interface ILineItemRows{
    lineItems: MpviLineItem[]
}

function LineItemRows({
    lineItems
}: ILineItemRows){
    return (
        <>
            {lineItems.map(item => {
                return (
                    <TableRow key={`line-item-${item.ID}`}>
                        <TableCell>{item.OpCode}</TableCell>
                        <TableCell>{item.Name}</TableCell>
                        <TableCell align={`right`}>${formatPrice(item.Price)}</TableCell>
                        <TableCell align={`center`}>{
                            item.Status === LineItemStatus.ACCEPTED ? 
                                <AcceptedIcon htmlColor={`#43a047`}/> :
                                <DeclinedIcon htmlColor={`#e53935`}/>}
                        </TableCell>
                    </TableRow>
                )
            })}
        </>
    )
}

export default LineItemRows