import {
    createEntityAdapter,
    createSlice,
    EntityState,
    PayloadAction,
    Update,
} from '@reduxjs/toolkit'
import { RootState } from 'rootReducer'
import { AppThunk } from 'store'
import apiFetch from 'api/apiFetch'
import modes from 'enums/appModes'
import { getEstimate, setMode } from 'modules/app'
import { MpviLineItem } from 'types/MpviLineItem'
import { MpviInspection } from 'types/MpviInspection'

export enum errorTypes {
    NONE,
    NO_SIGNATURE,
    DECLINE_SERVICES,
    UNHANDLED,
}

interface IState {
    estimateID: number
    estimateKey: string
    isSubmitting: boolean
    signature: string
    error: errorTypes
    inspections: EntityState<MpviInspection>
    lineItems: EntityState<MpviLineItem>
}

export interface IInspectionDataReceivedPayload {
    estimateID: number
    estimateKey: string
    signature: string
    inspections: MpviInspection[]
    lineItems: MpviLineItem[]
}

export const lineItemsAdapter = createEntityAdapter<MpviLineItem>({
    selectId: (lineItem) => lineItem.ID
})

export const inspectionsAdapter = createEntityAdapter<MpviInspection>({
    selectId: (inspection) => inspection.ID
})

const initialState: IState = {
    estimateID: 0,
    estimateKey: ``,
    isSubmitting: false,
    signature: ``,
    error: errorTypes.NONE,
    inspections: inspectionsAdapter.getInitialState(),
    lineItems: lineItemsAdapter.getInitialState()
}

const slice = createSlice({
    name: `customerInspectionView`,
    initialState: initialState,
    reducers: {
        submitStarted(state) {
            state.isSubmitting = true
        },
        submitFinished(state) {
            state.isSubmitting = false
        },
        submitFailure(state) {
            state.isSubmitting = false
        },
        lineItemUpdated(state, action: PayloadAction<Update<MpviLineItem>>) {

            lineItemsAdapter.updateOne(state.lineItems, action.payload)

        },
        lineItemsUpdated(state, action: PayloadAction<Update<MpviLineItem>[]>) {

            lineItemsAdapter.updateMany(state.lineItems, action.payload)

        },
        inspectionDataReceived(state, action: PayloadAction<IInspectionDataReceivedPayload>) {
            const payload = action.payload
            state.estimateID = payload.estimateID
            state.estimateKey = payload.estimateKey
            state.signature = payload.signature
            inspectionsAdapter.setAll(state.inspections, action.payload.inspections)
            lineItemsAdapter.setAll(state.lineItems, action.payload.lineItems)
        },
        signatureUpdated(state, action: PayloadAction<string>) {
            state.signature = action.payload
        },
        errorSet(state, action: PayloadAction<errorTypes>) {
            state.error = action.payload
        },
        errorCleared(state) {
            state.error = errorTypes.NONE
        }
    },
})

export const {
    submitStarted,
    lineItemUpdated,
    lineItemsUpdated,
    inspectionDataReceived,
    signatureUpdated,
    errorSet,
    errorCleared,
} = slice.actions

export const lineItemSelectors = lineItemsAdapter.getSelectors<RootState>(state => state.customerInspectionView.lineItems)
export const inspectionSelectors = inspectionsAdapter.getSelectors<RootState>(state => state.customerInspectionView.inspections)

export default slice.reducer

export const submitEstimate = (lineItems: MpviLineItem[]): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(submitStarted())
        const state = getState().customerInspectionView

        const payload = {
            ID: state.estimateID,
            EstimateKey: state.estimateKey,
            Signature: state.signature,
            LineItems: lineItems
        }

        await apiFetch(`api/mpvi/estimate/${state.estimateKey}`, {
            method: 'PUT',
            body: JSON.stringify(payload)
        })

        // load the latest estimate data into the state.
        dispatch(getEstimate(state.estimateKey))

        dispatch(setMode(modes.CONFIRMATION))

    } catch (error) {
        dispatch(errorSet(errorTypes.UNHANDLED))
    }
}