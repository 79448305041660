import { TextField, InputAdornment } from "@mui/material"
import { LineItemSource } from "api/types/LineItem"
import formatPrice from "helpers/formatPrice"
import { updateLineItem } from "modules/advisorInspectionView"
import React, { useState } from "react"
import { useAppDispatch } from "store"
import { MpviLineItem } from "types/MpviLineItem"

interface ILineItemPricedCell {
    lineItem: MpviLineItem
}

function PricedLineItemCell({
    lineItem
}: ILineItemPricedCell){
    const dispatch = useAppDispatch()
    const [price, setPrice] = useState<string>(formatPrice(lineItem.Price))
    const [priceFieldHasError, setPriceFieldHasError] = useState<boolean>(false)

    const handlePriceSubmit = () => {
        
        const newPrice = parseFloat(price)

        const priceChanged = lineItem.Price !== newPrice

        if (!priceFieldHasError && priceChanged) {

            setPrice(formatPrice(newPrice))

            dispatch(updateLineItem({
                ...lineItem,
                Price: newPrice,
            }))

        }
        
    }

    const handlePriceChange = (e: React.FocusEvent<HTMLInputElement>) => {
        
        const val = e.target.value
        
        const invalidPrice = isNaN(parseFloat(val))

        setPriceFieldHasError(invalidPrice)
        
        setPrice(val)

    }

    let field = (
        <TextField
            size={`small`}
            variant={`outlined`}
            value={price}
            label={`Price`}
            fullWidth
            InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>
            }}
            onChange={handlePriceChange}
            onBlur={handlePriceSubmit}
            error={priceFieldHasError}>
        </TextField>)

    return ( 
        <> {lineItem.Source === LineItemSource.DMS || lineItem.IsDeleted ? `$${price}` : field} </>
    )
}

export default PricedLineItemCell