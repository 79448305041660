import apiFetch from "api/apiFetch"
import { BaseLineItem } from "api/types/LineItem"

const updateEstimateLineItem = (estimateKey: string, lineItem: BaseLineItem): Promise<void> => {

    return apiFetch(`api/mpvi/estimate/${estimateKey}/line-items`, {
        method: 'PUT',
        body: JSON.stringify({
            ID: lineItem.ID,
            Price: lineItem.Price,
            Name: lineItem.Name,
            IsDeleted: lineItem.IsDeleted
        })
    })

}

export default updateEstimateLineItem