import React, { useEffect, useState } from 'react'
import { lineItemUpdated } from 'modules/customerInspectionView'
import { useAppDispatch } from 'store'
import formatPrice from 'helpers/formatPrice'
import { MpviLineItem } from 'types/MpviLineItem'
import { LineItemStatus } from 'api/types/LineItem'
import { Checkbox, Table, TableBody, TableCell, TableHead, TableRow } from '@mui/material'

export interface IPricedView {
    items: MpviLineItem[]
    onTotalChange(total: number): void
    disableCheckboxes: boolean
}

function PricedView({
    items,
    onTotalChange,
    disableCheckboxes
}: IPricedView) {
    const dispatch = useAppDispatch()
    const [total, setTotal] = useState<number>(0.00)

    const onItemSelect = (item: MpviLineItem) => {
        let currentStatus = item.Status
        dispatch(lineItemUpdated({
            id: item.ID,
            changes: {
                Status: currentStatus === LineItemStatus.ACCEPTED ? LineItemStatus.UNDECIDED : LineItemStatus.ACCEPTED
            }
        }))
    }

    useEffect(() => {
        const selectedItems = items.filter(item => item.Status === LineItemStatus.ACCEPTED)
        const val = selectedItems.reduce(((value, item) => value + item.Price), 0)
        setTotal(val)
        onTotalChange(val)
    }, [items, onTotalChange])

    const rows = items.map(item => (
        <TableRow key={item.ID}>
            <TableCell padding={`checkbox`} align={`center`}>
                <Checkbox
                    color={`default`}
                    size={`small`}
                    checked={item.Status === LineItemStatus.ACCEPTED}
                    disabled={disableCheckboxes}
                    onChange={() => onItemSelect(item)}
                ></Checkbox>
            </TableCell>
            <TableCell  style={{wordBreak: 'break-word'}}>{item.Name}</TableCell>
            <TableCell align={`right`}>${formatPrice(item.Price)}</TableCell>
        </TableRow>
    ))

    return (
        <Table size={`small`}>
            <TableHead>
                <TableRow>
                    <TableCell padding={`checkbox`}>Approved</TableCell>
                    <TableCell>Name</TableCell>
                    <TableCell width={120} align={`right`}>Price</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows}
                <TableRow>
                    <TableCell rowSpan={2} />
                    <TableCell align={`right`}>Total</TableCell>
                    <TableCell align={`right`}>${formatPrice(total)}</TableCell>
                </TableRow>
            </TableBody>
        </Table>
    )
}

export default PricedView