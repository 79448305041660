export enum InspectionSeverity {
    SAFETY_CONCERN,
    WARNING,
    OK
}

export type BaseInspection = {
    ID: number
    Name: string
    SectionName: string
    CustomerEstimateID: number
    Severity: InspectionSeverity
}