import React from 'react'
import ExpandMore from '@mui/icons-material/ExpandMore';
import ItemIcon from 'components/ItemIcon'
import { InspectionSeverity } from 'api/types/Inspection'
import { LineItemSource } from 'api/types/LineItem'
import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';

export interface ICategory {
    itemsCount: number
    source: LineItemSource
    severity?: InspectionSeverity
    children: React.ReactNode
    defaultExpanded?: boolean
}

function LineItemCategory({
    itemsCount,
    source,
    severity = undefined,
    children,
    defaultExpanded = true,
}: ICategory) {
    return (
        <Accordion defaultExpanded={itemsCount > 0 && defaultExpanded}>
            <AccordionSummary
                expandIcon={<ExpandMore />}
            >   
                <ItemIcon
                    source={source}
                    severity={severity}
                    itemCount={itemsCount}
                ></ItemIcon>
            </AccordionSummary>
            <AccordionDetails>
               {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default LineItemCategory