import { Table, TableBody, TableCell, TableRow } from "@mui/material"
import formatPrice from "helpers/formatPrice"
import React from "react"
import styles from './styles.module.scss'

interface ISubtotalContentProps {
    subtotal,
    tax
}

function SubtotalContentTable({
    subtotal,
    tax
}: ISubtotalContentProps){

    return (
        <Table size={`small`}>
            <TableBody>
                {/*<TableRow>
                    <TableCell width={150}>Subtotal</TableCell>
                    <TableCell width={120} align={`right`}>{formatPrice(subtotal)}</TableCell>
                </TableRow>
                <TableRow>
                    <TableCell>Tax</TableCell>
                    <TableCell align={`right`}>{formatPrice(tax)}</TableCell>
                </TableRow>*/}
                <TableRow >
                    <TableCell variant={`head`}>Total*</TableCell>
                    <TableCell variant={`head`} align={`right`}>${formatPrice(subtotal /* + tax */)}</TableCell>
                </TableRow>
                {<TableRow>
                    <TableCell className={styles.taxDisclaimer}>* Taxes and fees not included.</TableCell>
                </TableRow>}
            </TableBody>
        </Table>
    )

}

export default SubtotalContentTable