import React, { useEffect, useMemo, useState } from 'react'
import LineItemCategory from './components/Category'
import { useAppDispatch, useTypedSelector } from 'store'
import PricedView from './components/PricedView'
import { useSelector } from 'react-redux'
import { errorSet, errorTypes, inspectionSelectors, lineItemSelectors, submitEstimate } from 'modules/customerInspectionView'
import ErrorDialog from './components/ErrorDialog'
import SignatureContent from 'components/SignatureContent'
import SubtotalContentTable from 'components/SubtotalContentTable'
import AppGrid from 'componentsUI/AppGrid'
import { InspectionSeverity } from 'api/types/Inspection'
import { LineItemSource, LineItemStatus } from 'api/types/LineItem'
import { MpviInspection } from 'types/MpviInspection'
import ListView, { InspectionCategory } from './components/ListView'
import { Box, Button, Card, CardActions, CardContent, Typography } from '@mui/material'
import getUnsuppressedLineItems from 'helpers/getUnsuppressedLineItems'

function CustomerInspectionView() {
    const dispatch = useAppDispatch()
    const [subTotalSafetyConcern, setSubTotalSafetyConcern] = useState<number>(0.00)
    const [subTotalWarning, setSubTotalSafetyWarning] = useState<number>(0.00)
    const [subTotalAdditional, setSubTotalAdditional] = useState<number>(0.00)
    const [subTotal, setSubTotal] = useState<number>(0.00)
    const [taxCharged, setTaxCharged] = useState<number>(0.00)
    
    const inspections = useSelector(inspectionSelectors.selectAll)
    const lineItems = useSelector(lineItemSelectors.selectAll)
    
    const signature = useTypedSelector(state => state.customerInspectionView.signature)

    const unsuppressedLineItems = useMemo(() => getUnsuppressedLineItems(lineItems), [lineItems])

    const {
        TaxRate
    } = useTypedSelector(state => state.dealer)

    const okItems = useMemo(() => (
        inspections.filter(item => item.Severity === InspectionSeverity.OK)
    ), [inspections])
    const safetyConcernItems = useMemo(() => (
        unsuppressedLineItems.filter(item => item.Inspection?.Severity === InspectionSeverity.SAFETY_CONCERN)
    ), [unsuppressedLineItems])
    const warningItems = useMemo(() => (
        unsuppressedLineItems.filter(item => item.Inspection?.Severity === InspectionSeverity.WARNING)
    ), [unsuppressedLineItems])
    const additionalItems = useMemo(() => (
        unsuppressedLineItems.filter(item => item.Source === LineItemSource.EADVISOR_ADDITIONAL && item.InspectionID === null)
    ), [unsuppressedLineItems])
    const initialWorkItems = useMemo(() => (
        unsuppressedLineItems.filter(item => item.Source === LineItemSource.DMS)
    ), [unsuppressedLineItems])

    const selectedItems = useMemo(() => lineItems.filter(item => item.Status === LineItemStatus.ACCEPTED), [lineItems])

    const subTotalInitialWork: number = initialWorkItems.reduce((prev, current) => prev + current.Price, 0);

    const getCategories = (items: MpviInspection[]): InspectionCategory[] => {
        const categories: InspectionCategory[] = []

        items.forEach((item, index) => {
            const categoryName = item.SectionName
            const categoryIndex = categories.findIndex(cat => cat.name === categoryName)

            if (categoryIndex !== -1) {
                categories[categoryIndex].inspections.push(item)
            } else {
                categories.push({
                    id: index,
                    name: categoryName,
                    inspections: [item]
                })
            }
        })

        return categories
    }

    const okItemCategories = useMemo(() => getCategories(okItems), [okItems])

    useEffect(() => {
        const sub = subTotalInitialWork + subTotalSafetyConcern + subTotalWarning + subTotalAdditional
        const tax = sub * TaxRate
        setSubTotal(sub)
        setTaxCharged(tax)
    }, [subTotalInitialWork, subTotalSafetyConcern, subTotalWarning, subTotalAdditional, TaxRate])

    const handleSubmit = () => {

        if (!signature) {

            dispatch(errorSet(errorTypes.NO_SIGNATURE))

        } else if (selectedItems.length === 0) {

            dispatch(errorSet(errorTypes.DECLINE_SERVICES))

        } else {

            dispatch(submitEstimate(lineItems))

        }
        
    }

    return (
        <>
            <Typography variant='h6'>Please select the services you would like to authorize, sign your name, and click Submit.</Typography>
            <Box mb={2}>
                <LineItemCategory
                    itemsCount={initialWorkItems.length}
                    source={LineItemSource.DMS}
                >
                    <PricedView
                        items={initialWorkItems}
                        onTotalChange={(_) => {}}
                        disableCheckboxes={true}
                    ></PricedView>
                </LineItemCategory>
                <LineItemCategory
                    itemsCount={safetyConcernItems.length}
                    source={LineItemSource.GM_MPVI}
                    severity={InspectionSeverity.SAFETY_CONCERN}
                >
                    <PricedView
                        items={safetyConcernItems}
                        onTotalChange={setSubTotalSafetyConcern}
                        disableCheckboxes={false}
                    ></PricedView>
                </LineItemCategory>
                <LineItemCategory
                    itemsCount={warningItems.length}
                    source={LineItemSource.GM_MPVI}
                    severity={InspectionSeverity.WARNING}
                >
                    <PricedView
                        items={warningItems}
                        onTotalChange={setSubTotalSafetyWarning}
                        disableCheckboxes={false}
                    ></PricedView>
                </LineItemCategory>
                <LineItemCategory
                    itemsCount={okItems.length}
                    source={LineItemSource.GM_MPVI}
                    severity={InspectionSeverity.OK}
                    defaultExpanded={false}
                >
                    <ListView categories={okItemCategories || []} />
                </LineItemCategory>
                <LineItemCategory
                    itemsCount={additionalItems.length}
                    source={LineItemSource.EADVISOR_ADDITIONAL}
                >
                    <PricedView
                        items={additionalItems}
                        onTotalChange={setSubTotalAdditional}
                        disableCheckboxes={false}
                    ></PricedView>
                </LineItemCategory>
                <Card>
                    <CardContent>
                        
                        <SubtotalContentTable
                            subtotal={subTotal}
                            tax={taxCharged}/>

                        <AppGrid
                            justifyContent={`center`}
                            boxProps={{
                                pt: 2,
                            }}
                        >

                            <SignatureContent
                                signature={signature}
                                signatureRequired={true} />
                            
                        </AppGrid>
                    </CardContent>
                    
                    <CardActions>
                        <Button
                            variant={`contained`}
                            color={`primary`}
                            fullWidth
                            onClick={handleSubmit}
                        >{selectedItems.length > 0 ? `Accept` : `Decline`}</Button>
                    </CardActions>
                </Card>
            </Box>
            <ErrorDialog onConfirm={() => submitEstimate(lineItems)}/>
        </>
    )
}

export default CustomerInspectionView