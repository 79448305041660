import { TableContainer, Table } from "@mui/material"
import React from "react"

interface IResultsTable{
    children: JSX.Element[]
}

function ResultsTable({
    children
}: IResultsTable){
    return(
        <TableContainer>
            <Table size={`small`}>
                {children}
            </Table>
        </TableContainer>
    )
}

export default ResultsTable