import React from 'react'
import AppGrid from 'componentsUI/AppGrid'
import { LineItemSource } from 'api/types/LineItem'
import { InspectionSeverity } from 'api/types/Inspection'
import { SvgIcon, Typography, TypographyProps } from '@mui/material'
import { blue, green, grey, red, yellow } from '@mui/material/colors'
import CreateIcon from '@mui/icons-material/Create'
import CheckIcon from '@mui/icons-material/CheckCircle'
import ErrorIcon from '@mui/icons-material/Error'
import WarningIcon from '@mui/icons-material/Warning'

export interface IItemIcon {
    itemCount?: number
    source?: LineItemSource
    severity?: InspectionSeverity
    textVariant?: TypographyProps['variant']
    showText?: boolean
}

interface IComponentProps {
    name: string,
    color: string,
    icon: typeof SvgIcon | null
}

function ItemIcon({
    itemCount = 0,
    source = undefined,
    severity = undefined,
    textVariant = `body2`,
    showText = true,
}: IItemIcon) {
    let props: IComponentProps = {
        name: ``,
        color: ``,
        icon: null

    }
    const nameSuffix: string = itemCount > 1 ? `s` : ``
    if(severity !== undefined){
        let isSafetyConcern = severity === InspectionSeverity.SAFETY_CONCERN
        let isWarning = severity === InspectionSeverity.WARNING

        props.name = isSafetyConcern ? `Safety Concern` : (isWarning ? `Warning` : "Okay Item")
        props.color = isSafetyConcern ? red[400] : (isWarning ? yellow[600] : green[500])
        props.icon = isSafetyConcern ? ErrorIcon : (isWarning ? WarningIcon : CheckIcon)
    } else {
        let isDmsItem = source === LineItemSource.DMS
        props.name = isDmsItem ? `Initial Work Item` : `Additional`
        props.color = isDmsItem ? grey[900] : blue[400]
        props.icon = isDmsItem ? CreateIcon : WarningIcon
    }

    return (
        <AppGrid
            gridAutoFlow={`column`}
            columnSpacing={1}
            justifyContent={`start`}
            alignItems={`center`}
        >
            
            <props.icon
                htmlColor={props.color}
            />
            {showText &&
                <Typography variant={textVariant}>
                    {itemCount} {props.name}{nameSuffix}
                </Typography>
            }
        </AppGrid>
    )
}

export default ItemIcon
