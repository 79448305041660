import React, { useEffect, useMemo, useState } from 'react'
import { useAppDispatch } from 'store'
import { getInspectionData, inspectionSelectors, lineItemsSelectors, updateEstimate } from 'modules/advisorInspectionView'
import { useSelector } from 'react-redux'
import ErrorDialog from './components/ErrorDialog'
import { InspectionSeverity } from 'api/types/Inspection'
import { Typography, TextField, Card, CardContent } from '@mui/material'
import InspectionDetails from './components/InspectionDetails'
import { LineItemSource } from 'api/types/LineItem'
import { Box } from '@mui/system'
import { MpviInspection } from 'types/MpviInspection'

interface IAdvisorInspectionView {
    estimateMessage: string
}

function AdvisorInspectionView({
    estimateMessage
}: IAdvisorInspectionView) {
    const [message, setMessage] = useState<string>(``)

    const dispatch = useAppDispatch()
    const inspections = useSelector(inspectionSelectors.selectAll)
    const lineItems = useSelector(lineItemsSelectors.selectAll)
    
    useEffect(() => {

        dispatch(getInspectionData())

        if(estimateMessage !== null) {

            setMessage(estimateMessage)

        }

    }, [estimateMessage, dispatch])

    // sort inspections from highest severity to lowest.
    const sortInspections = (first: MpviInspection, second: MpviInspection) => {
        return first.Severity < second.Severity ? -1 :
            (first.Severity > second.Severity ? 1 : 0)
    }

    // Obtain a list of DMS inspection details.
    const dmsItems = lineItems.filter(li => li.Source === LineItemSource.DMS)
    const dmsInspectionDetails = dmsItems.length > 0 && (
        <InspectionDetails
            key={'dms'}
            name={`Initial Work`}
            sectionName={`Customer requested work items`}
            source={LineItemSource.DMS}
            lineItems={dmsItems}/>
    )

    // Obtain a list of yellow and red inspections and the line-items associated with them.
    const mpviInspectionDetails = inspections.filter(i => i.Severity !== InspectionSeverity.OK)
    .sort(sortInspections)
    .map(i => {
        let inspectionLineItems = lineItems.filter(li => li.InspectionID === i.ID)

        return <InspectionDetails
                    key={i.ID}
                    name={i.Name}
                    sectionName={i.SectionName}
                    inspection={i}
                    source={LineItemSource.GM_MPVI}
                    lineItems={inspectionLineItems}/>
    })

    // Obtain a list of additional line items generated through the eAdvisor CRM.
    const additionalItems = lineItems.filter(li => li.Source === LineItemSource.EADVISOR_ADDITIONAL && li.InspectionID === null)
    const additionalInspectionDetails = useMemo(() => (
        <InspectionDetails
            key={'add'}
            name={`Additional`}
            sectionName={`Additional work items created through eAdvisor CRM`}
            source={LineItemSource.EADVISOR_ADDITIONAL}
            lineItems={additionalItems}/>
    ), [additionalItems])

    const handleMessageChange = (event: React.ChangeEvent<HTMLInputElement>) => {

        setMessage(event.target.value)

    }

    const handleMessageFieldLostFocus = (event: React.FocusEvent<HTMLInputElement>) => {

        dispatch(updateEstimate(message))
        
    }

    return (
        <>
            <Typography>Please review the following MPVI items and update any prices as needed.</Typography>

            <TextField
                fullWidth
                focused
                multiline={true}
                maxRows={3}
                variant='outlined'
                label={`Inspection Message`}
                placeholder={`Provide a custom message to send with the inspection summary.`}
                value={message}
                onChange={handleMessageChange}
                onBlur={handleMessageFieldLostFocus}/>

            <Card>
                <CardContent>
                    <Box sx={{display: `flex`, flexDirection: `column`, rowGap: 2}}>
                        
                        {dmsInspectionDetails}
                        {mpviInspectionDetails}
                        {additionalInspectionDetails}
                        
                    </Box>
                </CardContent>
            </Card>
            <ErrorDialog/>
        </>
    )
}

export default AdvisorInspectionView