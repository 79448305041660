import { BaseCustomerEstimate } from "../CustomerEstimate"
import { BaseInspection } from "../Inspection"

export enum LineItemSource {
    EADVISOR_MPVI,
    EADVISOR_ADDITIONAL,
    GM_MPVI,
    DMS
}

export enum LineItemStatus {
    UNDECIDED,
    ACCEPTED,
    DECLINED,
}

export type BaseLineItem = {
    ID: number
    CustomerEstimateID: number
    InspectionID: number | null
    IsDeleted: boolean
    Name: string
    OpCode: string
    Price: number
    Source: LineItemSource
    Status: LineItemStatus
}

export type LineItem = BaseLineItem & {
    CustomerEstimate: BaseCustomerEstimate
    Inspection: BaseInspection
}