import apiFetch from "api/apiFetch"
import { LineItem } from "api/types/LineItem"
import { MpviLineItem } from "types/MpviLineItem"

const createEstimateLineItem = (estimateKey: string, lineItem: Partial<MpviLineItem>): Promise<LineItem> => {

    return apiFetch(`api/mpvi/estimate/${estimateKey}/line-items`, {
        method: 'POST',
        body: JSON.stringify(lineItem),
    })

}

export default createEstimateLineItem