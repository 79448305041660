import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import Vehicle from 'api/types/Vehicle'

const initialState: Vehicle = {
    AutomakerBrandName: ``,
    AutomakerModelName: ``,
    Year: ``,
}

const slice = createSlice({
    name: `vehicle`,
    initialState,
    reducers: {
        setVehicle: (state, action: PayloadAction<Vehicle>) => action.payload,
        resetVehicle: () => initialState,
    },
})

export const {
    setVehicle,
    resetVehicle,
} = slice.actions

export default slice.reducer