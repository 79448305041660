import { TableRow, TableCell, TableHead, TableBody } from "@mui/material"
import React from "react"
import { MpviInspection } from "types/MpviInspection"
import ResultsTable from "../ResultsTable";
import InspectionRows from "./components/InspectionRows";

interface IInspectionTable{
    inspections: MpviInspection[]
}

function InspectionTable({
    inspections
}: IInspectionTable){
    return (
        <ResultsTable>
            <TableHead>
                <TableRow>
                    <TableCell>Name</TableCell>
                    <TableCell>Section Name</TableCell>
                    <TableCell align={`center`}>Approved</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <InspectionRows inspections={inspections}/>
            </TableBody>
        </ResultsTable>
    )
}

export default InspectionTable