import { TableRow, TableCell, TableHead, TableBody } from "@mui/material"
import React from "react"
import { MpviLineItem } from "types/MpviLineItem";
import ResultsTable from "../ResultsTable";
import LineItemRows from "./components/LineItemRows";

interface ILineItemTable {
    lineItems: MpviLineItem[]
}

function LineItemTable({
    lineItems
}: ILineItemTable){
    return (
        <ResultsTable>
            <TableHead>
                <TableRow>
                    <TableCell>OpCode</TableCell>
                    <TableCell>Item</TableCell>
                    <TableCell align={`right`}>Price</TableCell>
                    <TableCell align={`center`}>Approved</TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                <LineItemRows lineItems={lineItems}/>
            </TableBody>
        </ResultsTable>
    )
}

export default LineItemTable