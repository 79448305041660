import { inspectionSelectors, lineItemSelectors } from "modules/customerInspectionView"
import React, { useCallback, useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"
import ResultsAccordian from "./components/ResultsAccordian"
import { LineItemSource, LineItemStatus } from "api/types/LineItem"
import modes from "enums/appModes"
import userModes from "enums/userModes"
import { useTypedSelector } from "store"
import SignatureContent from "components/SignatureContent"
import SubtotalContentTable from "components/SubtotalContentTable"
import AppGrid from "componentsUI/AppGrid"
import { InspectionSeverity } from "api/types/Inspection"
import { MpviLineItem } from "types/MpviLineItem"
import { Box, Card, CardContent, Typography } from "@mui/material"
import LineItemTable from "./components/LineItemTable"
import { MpviInspection } from "types/MpviInspection"
import InspectionTable from "./components/InspectionTable"
import getUnsuppressedLineItems from "helpers/getUnsuppressedLineItems"

interface ICustomerSubmissionResultsView {
    view: modes,
    user: userModes
}

function CustomerSubmissionResultsView({
    view,
    user
}: ICustomerSubmissionResultsView) {

    const [subtotal, setSubtotal] = useState<number>(0.00)
    const [tax, setTax] = useState<number>(0.00)

    const lineItems = useSelector(lineItemSelectors.selectAll)
    const inspections = useSelector(inspectionSelectors.selectAll)

    const signature = useTypedSelector(state => state.customerInspectionView.signature)

    const {
        TaxRate
    } = useTypedSelector(state => state.dealer)

    const unsuppressedLineItems = useMemo(() => getUnsuppressedLineItems(lineItems), [lineItems])

    const getLineItemsBySeverity = useCallback((severity: InspectionSeverity) => unsuppressedLineItems.filter(item => item.Inspection?.Severity === severity), [unsuppressedLineItems])
    const getLineItemsBySource = useCallback((source: LineItemSource) => unsuppressedLineItems.filter(item => item.Source === source), [unsuppressedLineItems])
    const getInspectionsBySeverity = useCallback((severity: InspectionSeverity) => inspections.filter(i => i.Severity === severity), [inspections])
    const getSubtotal = useCallback(() => unsuppressedLineItems.reduce((prev, current) => {
        
        return current.Status === LineItemStatus.ACCEPTED ?
            prev + current.Price :
            prev

    }, 0), [unsuppressedLineItems])
    
    const safetyConcernItems: MpviLineItem[] = getLineItemsBySeverity(InspectionSeverity.SAFETY_CONCERN)
    const warningItems: MpviLineItem[] = getLineItemsBySeverity(InspectionSeverity.WARNING)
    const okItems: MpviInspection[] = getInspectionsBySeverity(InspectionSeverity.OK)
    const additionalItems: MpviLineItem[] = getLineItemsBySource(LineItemSource.EADVISOR_ADDITIONAL).filter(li => li.InspectionID === null)
    const initialWorkItems: MpviLineItem[] = getLineItemsBySource(LineItemSource.DMS)

    useEffect(() => {

        const subtotal = getSubtotal()

        setSubtotal(subtotal)

        setTax(subtotal * TaxRate)

    }, [TaxRate, getSubtotal])

    const header = useMemo(() => {

        if(view === modes.CONFIRMATION) {

            return (
                <React.Fragment>
                    <Typography variant="h6">
                        Thank you!
                    </Typography>
                    <Typography component="p">
                        Your approved and/or declined services have been successfully recorded. Your advisor will be in touch on the status of your vehicle.
                    </Typography>
                </React.Fragment>
            )

        }

        if (user === userModes.CUSTOMER) {

            return <Typography variant='h6'>Your signed estimate is available below for review.</Typography>

        }

    }, [view, user])

    return (
        <Box>
            {header}
            <ResultsAccordian
                title={`Initial Work`}
                itemCount={initialWorkItems.length}
                color={`#f5f5f5`}
                isOpenDefault={initialWorkItems.length > 0}>

                <LineItemTable lineItems={initialWorkItems}/>
            </ResultsAccordian>
            <ResultsAccordian
                title={`Safety Concerns`}
                itemCount={safetyConcernItems.length}
                color={`#ffcdd2`}
                isOpenDefault={safetyConcernItems.length > 0}>

                <LineItemTable lineItems={safetyConcernItems}/>
            </ResultsAccordian>
            <ResultsAccordian
                title={`Warnings`}
                itemCount={warningItems.length}
                color={`#fff9c4`}
                isOpenDefault={warningItems.length > 0}>

                <LineItemTable lineItems={warningItems}/>
            </ResultsAccordian>
            <ResultsAccordian
                title={`OK`}
                itemCount={okItems.length}
                color={`#c8e6c9`}>

                <InspectionTable inspections={okItems}/>
            </ResultsAccordian>
            <ResultsAccordian
                title={`Additional`}
                itemCount={additionalItems.length}
                color={`#bbdefb`}
                isOpenDefault={additionalItems.length > 0}>

                <LineItemTable lineItems={additionalItems}/>
            </ResultsAccordian>
            <Card>
                <CardContent>
                    
                    <SubtotalContentTable
                        subtotal={subtotal}
                        tax={tax}/>

                    <AppGrid
                        justifyContent={`center`}
                        boxProps={{
                            pt: 2,
                        }}
                    >

                        <SignatureContent
                            signature={signature}
                            signatureRequired={false} />
                        
                    </AppGrid>
                </CardContent>
            </Card>
        </Box>
    )
}

export default CustomerSubmissionResultsView