import React, { useState } from 'react'
import ArrowIconDown from '@mui/icons-material/KeyboardArrowDown';
import styles from './styles.module.scss'
import { Accordion, AccordionSummary, Typography, AccordionDetails } from '@mui/material';

export interface IResultsAccordian {
    title: string
    color: string
    isOpenDefault?: boolean
    itemCount: number
    children: JSX.Element | JSX.Element[]
}

function ResultsAccordian({
    title,
    color,
    isOpenDefault = false,
    itemCount,
    children
}: IResultsAccordian) {
    const [isOpen, setIsOpen] = useState<boolean>(isOpenDefault)

    const handleAccordionChange = () => {
        setIsOpen(!isOpen)
    }

    return (
        <Accordion 
            expanded={isOpen}
            onChange={handleAccordionChange}
            className={styles.container}>

            <AccordionSummary
                expandIcon={<ArrowIconDown className={styles.arrowButton}/>}
                style={{ backgroundColor: color }}
                className={styles.summary}>

                <Typography 
                    noWrap
                    variant={`subtitle1`}
                >{title} ({itemCount})</Typography>
            </AccordionSummary>
            <AccordionDetails>
                {children}
            </AccordionDetails>
        </Accordion>
    )
}

export default ResultsAccordian