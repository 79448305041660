import {
    createSlice,
    PayloadAction,
} from '@reduxjs/toolkit'
import { AppThunk } from 'store'
import modes from 'enums/appModes'
import userModes from 'enums/userModes'
import { setDealer } from 'modules/dealer'
import { setUser } from 'modules/user'
import { setCustomer } from 'modules/customer'
import { setVehicle } from 'modules/vehicle'
import { videosReceived } from 'modules/videos'
import { inspectionDataReceived } from 'modules/customerInspectionView'
import { CustomerEstimate, EstimateStatus } from 'api/types/CustomerEstimate'
import MPVIStatus from 'enums/mpviStatus'
import { estimateMessageReceived } from 'modules/estimateMessage'
import getCustomerEstimate from 'api/getCustomerEstimate'
import updateCustomerEstimateReadStatus from 'api/updateCustomerEstimateReadStatus'
import { MpviLineItem } from 'types/MpviLineItem'

interface IApp {
    mode: modes
    userMode: userModes
    estimateKey: string
    title: string
    loadingText: string
    errorText: string
}

interface IInitAppStartPayload {
    estimateKey: IApp['estimateKey']
    userMode: IApp['userMode']
}

interface IEstimateFinishedPayload {
    isEstimateCompleted: boolean,
    isEstimateSent: boolean
}

const initialState: IApp = {
    mode: modes.UNKNOWN,
    userMode: userModes.CUSTOMER,
    estimateKey: ``,
    title: `Vehicle Inspection Summary`,
    loadingText: ``,
    errorText: ``,
}

const slice = createSlice({
    name: `app`,
    initialState,
    reducers: {
        getEstimateStart(state) {
            state.mode = modes.LOADING
            state.loadingText = `Retrieving Estimate...`
        },
        getEstimateFinish(state, action: PayloadAction<IEstimateFinishedPayload>) {
            const {
                isEstimateCompleted,
                isEstimateSent
            } = action.payload

            state.mode = isEstimateCompleted ?
                    modes.REVIEW :
                    (state.userMode === userModes.CUSTOMER ?
                        modes.SUMMARY:
                        isEstimateSent ?
                            modes.REVIEW :
                            modes.INSPECTION)

            state.loadingText = initialState.loadingText
        },
        getEstimateFailure(state, action: PayloadAction<string>) {
            state.mode = modes.ERROR
            state.errorText = action.payload
        },
        initAppStart(state) {
            state.mode = modes.LOADING
            state.loadingText = `Intializing...`
        },
        initAppFinish(state, action: PayloadAction<IInitAppStartPayload>) {
            state.estimateKey = action.payload.estimateKey
            state.userMode = action.payload.userMode
            state.loadingText = initialState.loadingText
            state.mode = modes.UNKNOWN
        },
        initAppFailure(state, action: PayloadAction<string>) {
            state.errorText = action.payload
            state.mode = modes.ERROR
        },
        setMode(state, action: PayloadAction<modes>) {
            state.mode = action.payload
        },
        reset: () => initialState,
    },
})

export const {
    getEstimateStart,
    getEstimateFinish,
    getEstimateFailure,
    initAppStart,
    initAppFinish,
    initAppFailure,
    setMode,
    reset,
} = slice.actions

export default slice.reducer

export const getEstimate = (estimateKey: string): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(getEstimateStart())
        const res: CustomerEstimate = await getCustomerEstimate(estimateKey)

        const userMode: userModes = getState().app.userMode
        const estimateCompleted = res.Status === EstimateStatus.CUSTOMER_APPROVED || res.Status === EstimateStatus.CUSTOMER_DECLINED

        // if the estimate is not completed and the customer is opening the MPVI results, mark the estimate as `opened`.
        if (userMode === userModes.CUSTOMER && !estimateCompleted) {
            await updateCustomerEstimateReadStatus(estimateKey)
        }

        dispatch(setUser(res.User))

        dispatch(setCustomer(res.Customer))

        dispatch(setDealer(res.Dealer))

        dispatch(setVehicle(res.CustomerVehicle))

        dispatch(videosReceived(res.Videos))

        dispatch(estimateMessageReceived(res.Message))

        let lineItems: MpviLineItem[] = res.LineItems.map(li => {
            let inspection = res.Inspections.find(i => i.ID === li.InspectionID)

            return {
                ...li,
                Inspection: inspection ? inspection : null
            }
        })

        dispatch(inspectionDataReceived({
            estimateID: res.ID,
            estimateKey,
            inspections: res.Inspections,
            lineItems: lineItems,
            signature: res.Signature,
        }))

        dispatch(getEstimateFinish({
            isEstimateCompleted: estimateCompleted,
            isEstimateSent: res.MPVIStatus > MPVIStatus.WaitingOnApproval
        }))

    } catch (error) {
        console.log(error)
        dispatch(getEstimateFailure(error.message))
    }
}