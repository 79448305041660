import React, { useState } from 'react'
import ActionField, { types as actionFieldTypes } from '../../../../../InspectionActionField'
import { useAppDispatch } from 'store'
import { createLineItem } from 'modules/advisorInspectionView'
import { InputAdornment, TableCell, TableRow, TextField } from '@mui/material'

interface INewLineItemTableRow {
    inspectionID: number | null
}

function NewLineItemTableRow({
    inspectionID = null
}: INewLineItemTableRow) {
    const dispatch = useAppDispatch()
    const [name, setName] = useState<string>(``)
    const [price, setPrice] = useState<string>(``)
    const [nameFieldHasError, setNameFieldHasError] = useState<boolean>(false)
    const [priceFieldHasError, setPriceFieldHasError] = useState<boolean>(false)

    const isEmptyInput = (input: string) => input === ``

    const containsInvalidFields = () => isEmptyInput(name) || isEmptyInput(price) || isNaN(parseFloat(price))

    const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        setNameFieldHasError(isEmptyInput(val))
        setName(val)
    }

    const handlePriceChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const val = e.target.value
        const invalidPrice = isNaN(parseFloat(val))
        setPriceFieldHasError(invalidPrice)
        setPrice(val)
    }

    const handleLostFocus = () => {
        
        if(!containsInvalidFields()){

            dispatch(createLineItem({
                Name: name,
                Price: parseFloat(price),
                InspectionID: inspectionID
            }))

        }
    
    }

    return (
        <TableRow>
            <TableCell>
                <TextField
                    size={`small`}
                    variant={`outlined`}
                    value={name}
                    label={`Name`}
                    multiline
                    fullWidth
                    onChange={handleNameChange}
                    onBlur={handleLostFocus}
                    error={nameFieldHasError}
                    autoFocus={true}
                ></TextField>
            </TableCell>
            <TableCell>
                <TextField
                    size={`small`}
                    variant={`outlined`}
                    value={price}
                    label={`Price`}
                    fullWidth
                    InputProps={{
                        startAdornment: <InputAdornment position="start">$</InputAdornment>
                    }}
                    onChange={handlePriceChange}
                    onBlur={handleLostFocus}
                    error={priceFieldHasError}
                ></TextField>
            </TableCell>
            <TableCell>
                <ActionField
                    type={actionFieldTypes.ADD}
                    onClick={() => null} // the input field onBlur event handler will handle adding the service
                    disabled={containsInvalidFields() || nameFieldHasError || priceFieldHasError}
                ></ActionField>
            </TableCell>
        </TableRow>
    )
}

export default NewLineItemTableRow