import React from 'react'
import { useAppDispatch, useTypedSelector } from 'store'
import { errorTypes, errorCleared, getInspectionData } from 'modules/advisorInspectionView'
import { Button, Dialog, DialogActions, DialogContent, DialogContentText } from '@mui/material'

function ErrorDialog() {
    const dispatch = useAppDispatch()
    const {
        error
    } = useTypedSelector(state => state.advisorInspectionView)

    const handleClose = () => {
        dispatch(errorCleared())
    }

    const handleRetry = () => {
        dispatch(getInspectionData())
    }

    let text = ``
    let buttons = <Button
        onClick={handleClose}
        color={`primary`}
        autoFocus
    >OK</Button>

    switch (error) {
        case errorTypes.ADD_FAILURE:
            text = `There was a problem attempting to add the new line item. Please contact support if the problem persists.`
            break
        case errorTypes.UPDATE_FAILURE:
            text = `There was a problem attempting to update the line item. Please contact support if the problem persists.`
            break
        case errorTypes.REMOVE_FAILURE:
            text = `There was a problem attempting to delete the line item. Please contact support if the problem persists.`
            break
        case errorTypes.UPDATE_ESTIMATE_FAILURE:
            text = `There was a problem attempting to update the estimate. Please contact support if the problem persists.`
            break
        case errorTypes.LOAD_FAILURE:
            text = `There was a problem retrieving the line items. Please contact support if the problem persists.`
            buttons = <>
                <Button
                    onClick={handleClose}
                    color={`primary`}
                >Cancel</Button>
                <Button
                    onClick={handleRetry}
                    color={`primary`}
                    autoFocus
                >Try Again</Button>
            </>
            break
    }

    return (
        <Dialog
            open={error !== errorTypes.NONE}
            onClose={handleClose}
        >
            <DialogContent>
                <DialogContentText>{text}</DialogContentText>
            </DialogContent>
            <DialogActions>{buttons}</DialogActions>
        </Dialog>
    )
}

export default ErrorDialog