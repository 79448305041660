import React from 'react'
import theme from 'assets/theme'
import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';

const MaterialUiProvider = ({children}) => {
    return (
        <ThemeProvider theme={theme}>
            <CssBaseline/>
            {children}
        </ThemeProvider>
    )
}

export default MaterialUiProvider